@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}
.body_section{
  padding: 20px 50px;
}



/*active class Header Section*/
.header_section a{
  width: 100%;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif !important;
}
.header_section a.active {
  color: black;
/*  padding-bottom: 12px;*/
  font-weight: 700;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif !important;
}

.analytics_panel_section .analytics_box_section{
  border: 1px solid #ccc;
  padding: 15px;
  width: 273px;
  border-radius: 5px;
  margin-right: 20px;
}
.analytics_panel_section .analytics_box_section h2{
  font-size: 32px;
  line-height: 38px;
  color: #000;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  margin: 0px 0px 5px 0px;
}

.analytics_panel_section .analytics_box_section p{
  font-size: 14px;
  line-height: 18px;
  color: #000;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  margin: 0px;
}
.analytics_panel_section .analytics_box_section span{
  font-size: 10px;
  line-height: 13px;
  color: #7C7C7C;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  margin: 0px;
}
.analytics_panel_section .chart_box{
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 30px;
}
.analytics_panel_section .chart_box .highcharts-title{
  font-size: 16px !important;
  line-height: 21px !important;
  color: #000 !important;
  font-family: "Inter", sans-serif !important;
  margin: 0px 0px 20px !important;
  font-weight: 700 !important;
}




/* table_section*/
.table_section .chakra-table__container{
  width: 100%;

}
.table_section .chakra-table__container .chakra-table{
  width: 100%;
  border: 1px solid #ccc;
  border-spacing: 0px;
}
.table_section .chakra-table__container .chakra-table thead{
  background-color: #F5F5F5;
  border-spacing: 0px;

}
.table_section .chakra-table__container .chakra-table thead tr th{
  border-spacing: 0px;
  text-transform:capitalize ;
  font-size: 14px;
  font-weight: 700;
  font-family:  'Open Sans', sans-serif !important;
  color: #000000;
  padding: 12px 15px;
  text-align: left;
   border-bottom: 1px solid #CCCCCC;

}
.table_section .chakra-table__container .chakra-table tbody tr td{
  border-spacing: 0px;
  font-size: 14px;
  font-weight: 400;
  font-family:  'Open Sans', sans-serif !important;
  color: #000;
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}
.table_section .chakra-table__container .chakra-table tbody tr:last-child td{
/*  border-bottom: 0px;*/
}
.table_section .chakra-table__container .chakra-table tbody tr td a{
  color: #0085FF;
  text-decoration: none;
  text-align: left;
}
.table_section .chakra-table__container .chakra-table tbody tr td .table_section .chakra-table__container .chakra-table{
  border: 0px;
}
.table_section .chakra-table__container .chakra-table tbody tr td .table_section .chakra-table__container .chakra-table tbody tr:last-child td{
  border-bottom: 0px;
}

.table_section .chakra-table__container .chakra-table tbody tr td .chakra-menu__menu-list{
  width: 200px;
}

.table_section .chakra-table__container .chakra-table tbody tr td .chakra-menu__menu-list button{
  font-size: 12px;
  padding: 8px 15px;
  display: inline-block;
  height: unset;
}
.table_section .chakra-table__container .chakra-table tbody tr td .chakra-menu__menu-list hr{
  margin: 0px;
} 




/*Pagination Css Style*/

.pagination {
  padding-top: 30px;
  margin: 0px;
  list-style-type: none;
}
.pagination li{
  display: inline-block;
}
.pagination li a{
  padding: 5px 20px;
  display: block;
  border: 1px solid #ccc;
  color: #000 !important;
}
.pagination li a:hover{
  color: #1400FF !important;
}
.pagination li:first-child a{
  border-radius: 5px 0px 0px 5px;
}
.pagination li:last-child a{
  border-radius: 0px 5px 5px 0px;
}

.pagination .page-item.active,.page-link .page-link.active{
  background-color: #F5F5F5;
  border: 1px solid #ccc;
  color: black;
}


.token.property{
  color: #DF3079 !important;
}


.disabled .field_section .chakra-input.css-1cjy4zv{
  color: black;
  font-weight: 400;
  opacity: 1;
  background-color: #CCCCCC;
}

/*inputt Csss*/
.field_section{
  margin-bottom: 20px;
}
.field_section .chakra-form__label{
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-bottom: 5px;
}
.field_section label{
  font-size: 12px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
  display: block;
}
.field_section .chakra-input{
  border: 1px solid #ccc;
  height: 43px;
  padding: 10px 15px;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
}
.field_section .chakra-select{
  border: 1px solid #ccc;
  height: unset;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 15px;
  font-family: 'Lato', sans-serif;
}


.red{
  color:red !important;
}

.green{
  color:green !important;
}

#Header_User{
    gap: 0px;
    justify-content: flex-start;
}


.active {
  font-weight: bold;
  color: black;
  font-weight: 700;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif !important;
}


.line-break {
   white-space: normal;
   word-break: break-word;
   overflow-wrap: break-word;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
}
